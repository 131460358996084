<template>
  <div class="page">
    <el-form size="small"
             :inline="true"
             class="query-form"
             ref="searchForm"
             @keyup.enter.native="refreshList()"
             @submit.native.prevent>
      <el-form-item prop="type">
        <el-input size="small"
                  v-model="searchStr"
                  placeholder="请输入模板名称" maxlength="66"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="pageNo=1;refreshList()"
                   size="small"
                   icon="el-icon-search">查询</el-button>
        <el-button @click="pageNo=1;resetSearch()"
                   size="small"
                   icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <div class="f_r m_r1">
        <button type="button"
                @click="view('add', null)"
                class="el-button el-button--primary el-button--small">
          <i class="el-icon-plus"></i><span>新建</span>
        </button>
      </div>
    </el-form>
    <div class="top bg-white"
         id="reorganize">
      <el-table :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 60px)"
                @selection-change="selectionChangeHandle"
                class="table">
        <el-table-column type="selection"
                         width="50" />
        <el-table-column sortable
                         label="模板名称"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <router-link :to="{path:'/printTemplates',query:{id:scope.row.id}}">
              <span>{{scope.row.name}}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="creatorDatetime"
                         sortable
                         label="创建时间"
                         show-overflow-tooltip />
        <el-table-column prop="creator"
                         sortable
                         label="创建人"
                         show-overflow-tooltip />
        <el-table-column fixed="right"
                         label="操作"
                         width="300">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="primary"
                       plain
                       v-if="scope.row.status===0"
                       @click="view('edit', scope.row)">编辑
            </el-button>
            <el-button size="mini"
                       type="primary"
                       plain
                       v-if="scope.row.status===0"
                       @click="assignPermissions(scope.row)">分配权限
            </el-button>
            <el-button size="mini"
                       type="primary"
                       plain
                       v-if="scope.row.status===0"
                       @click="release(scope.row.id)">发布
            </el-button>
            <el-button size="mini"
                       type="danger"
                       plain
                       @click="del( scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle"
                     @current-change="currentChangeHandle"
                     :current-page="pageNo"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pageSize"
                     :total="total"
                     background
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <declarationFormTemplateForm ref="declarationFormTemplateForm"
                                 @refreshDataList="refreshList"></declarationFormTemplateForm>
    <declarationFormTemplateAssignPermissons ref="declarationFormTemplateAssignPermissons">
    </declarationFormTemplateAssignPermissons>
    <el-dialog title="发布"
               :visible.sync="dialogVisible"
               width="30%"
               :before-close="handleClose">
      <el-select v-model="exhibitionValue"
                 filterable
                 placeholder="请选择">
        <el-option v-for="item in exhibition"
                   :key="item.id"
                   :label="item.exhibitionName"
                   :value="item.id">
        </el-option>
      </el-select>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="release1()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  declarationFormTemplateListApi,
  declarationFormTemplateDeleteApi,
  declarationFormTemplateReleaseApi,
} from '@/api/declaration/declarationFormTemplate'

import declarationFormTemplateForm from './form' // 新建弹窗

import declarationFormTemplateAssignPermissons from './assignPermissons/index' // 新建弹窗

export default {
  components: {
    declarationFormTemplateForm,
    declarationFormTemplateAssignPermissons,
  },
  data() {
    return {
      searchStr: '',
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dataListSelections: [],
      dictTypeTitle: '',
      rightVisible: false,
      loading: false,
      dialogVisible: false,
      exhibition: [],
      exhibitionValue: '',
      releaseId: '',
    }
  },
  created() {
    this.refreshList()
    this.exhibitionList()
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 获取数据列表
    refreshList() {
      this.loading = true
      var params = {
        current: this.pageNo,
        size: this.pageSize,
        searchStr: this.searchStr,
      }
      declarationFormTemplateListApi(params).then((res) => {
        if (res && res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.loading = false
        }
      })
    },
    exhibitionList() {
      this.$axios(
        this.api.infor.informationList,
        {
          exhibitionName: '',
          pageNo: 1,
          pageSize: 9999,
        },
        'get'
      ).then((data) => {
        if (data && data.status) {
          this.exhibition = data.data.records
        }
      })
    },
    // 新建、编辑
    view(method, row) {
      this.$refs.declarationFormTemplateForm.init(method, row)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 删除
    del(id) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        declarationFormTemplateDeleteApi(id).then((res) => {
          if (res && res.status) {
            this.$message.success('删除成功')
            this.refreshList()
            this.$dictUtils.refreshDictList()
          }
        })
      })
    },
    /**
     * 重置搜索
     */
    resetSearch() {
      this.searchStr = ''
      this.refreshList()
    },
    /**
     * 分配权限
     */
    assignPermissions(row) {
      // declarationFormTemplateAssignPermissons
      this.$refs.declarationFormTemplateAssignPermissons.init(row.id)
    },
    /**
     * 发布
     */
    release(id) {
      this.exhibitionValue = ''
      this.dialogVisible = true
      this.releaseId = id
    },
    release1() {
      if(this.exhibitionValue==''){
        this.$message.error("请选择发布展览")
      }else{
          declarationFormTemplateReleaseApi(
          this.releaseId,
          this.exhibitionValue
        ).then((res) => {
          if (res && res.status) {
            this.$message.success(res.msg)
            this.dialogVisible = false
            this.refreshList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }

    },
  },
}
</script>
