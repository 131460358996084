<template>
  <el-dialog :title="title"
             :close-on-click-modal="false"
             append-to-body
             :visible.sync="visible">
    <el-form size="small"
             :model="inputForm"
             :rules="dataRule"
             v-loading="loading"
             ref="inputForm"
             @keyup.enter.native="doSubmit()"
             label-width="140px"
             @submit.native.prevent>
      <el-form-item label="模板名称："
                    prop="name">
        <el-input v-model.trim="inputForm.name"
                  maxlength="50"
                  placeholder="请输入模板名称"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button size="small"
                 @click="visible = false">取消</el-button>
      <el-button size="small"
                 type="primary"
                 @click="doSubmit()"
                 v-noMoreClick>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  declarationFormTemplateSaveApi,
  declarationFormTemplateUpdateApi,
} from '@/api/declaration/declarationFormTemplate'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      title: '',
      method: '',
      inputForm: {
        id: '',
        name: '',
      },
      fieldNameOptions: [],
      dataRule: {
        name: [
          { required: true, message: '模板名称不能为空', trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    init(method, row) {
      this.method = method
      this.inputForm.id = ''
      if (method === 'add') {
        this.title = `新建模板`
        this.inputForm.flag = row
      } else if (method === 'edit') {
        this.title = '编辑模板'
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs['inputForm'].resetFields()
        if (method === 'edit') {
          // 修改
          this.inputForm = this.recover(this.inputForm, row)
        }
      })
    },
    // 表单提交
    doSubmit() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.title == `新建模板`) {
            declarationFormTemplateSaveApi(this.inputForm).then((data) => {
              this.loading = false
              if (data && data.status) {
                this.$message.success(data.msg)
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          } else {
            declarationFormTemplateUpdateApi(this.inputForm).then((data) => {
              this.loading = false
              if (data && data.status) {
                this.$message.success(data.msg)
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        }
      })
    },
  },
}
</script>
